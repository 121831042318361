$color-primary: #ff5600;
$color-primary-on-top: #fff;
$color-primary-faded: #ff9560;
$color-secondary: #0fbafa;
$color-error: #f00;
$color-error-on-top: #fff;
$color-dark-red: #ea4335;
$color-text: #263F41;
$color-dark-gray: #9b9e9f;
$color-dark-gray-on-top: #fff;
$color-app-background: #fff;
$color-divider: #cdd1d0;
$color-default-avatar-background: #d1d3d4;
$color-disabled-button: rgb(0 0 0 / 25%);
$color-disabled-button-text: #fff;
$color-create-menu-days-holder: #ffffff7f;
$color-dream-machine-background: #f4e9e1;
$color-dream-machine-input: #ffe4d6;
