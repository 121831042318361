
// Screen elements
$eln-card-radius: 30px;
$eln-card-padding: 20px;
$eln-adds-holder-height: 80px;
$header-height: 45px;
$content-margin: 14px;
$content-max-width: min(calc(100vw - 2 * #{$content-margin}), 450px);
$content-max-height: calc(100% - #{$header-height});
$content-max-padding: calc((100vw - #{$content-max-width}) / 2);
$card-screen-max-height: calc(100% - 2 * #{$content-margin});
$app-menu-bar-width: min(calc(#{$content-max-width} + 20px), 98vw);
$app-menu-width: calc(#{$content-max-width} + 2 * #{$content-margin});
$login-logo-height: 70px;
$footer-min-height: 80px;
$footer-marbodal-logo-height: 31px;
$profile-activity-button-height: 46px;
$profile-activity-button-width: 77px;
$create-menu-footer-height: 90px;
$create-menu-day-numbers-holder-height: 70px;
$image-recipe-max-height: 200px;

// Z-indexes
$elevation-main-holder: 1;
$elevation-create-menu-footer: 5;
$elevation-family-member-spinner: 10;
$elevation-app-loader: 20;
$elevation-menu: 99;

// Buttons
$button-max-width: 310px;
$button-height-big: 70px;
$button-height-medium: 50px;
$button-height-small: 35px;

// Tags
$tag-height-big: 35px;
$tag-height-medium: 26px;
$tag-height-small: 20px;
