/**
Font has been downloaded from here:
https://en.fontsloader.com/types/avenir-next
*/

@font-face {
  font-family: "Avenir Next";
  src: url("../assets/fonts/AvenirNextCyr-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../assets/fonts/AvenirNextCyr-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../assets/fonts/AvenirNextCyr-Demi.ttf") format("truetype");
  font-weight: 600;
}

@mixin font-regular {
  font-family: "Avenir Next", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
}

@mixin font-semibold {
  font-family: "Avenir Next", sans-serif;
  font-weight: 500;
  font-style: normal;
}

@mixin font-bold {
  font-family: "Avenir Next", sans-serif;
  font-weight: 600;
  font-style: normal;
}