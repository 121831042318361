@use "sass:map";
@use '@angular/material' as mat;
@use "fonts" as fonts;

// Include this once, and only once, in your application
@include mat.core;

/**
Here we define a configuration for Angular Material.
We have been followed these guides:
https://material.angular.io/guide/theming
https://material.angular.io/guide/typography
But everything should be summarised in README.md :)

Make sure to skip the step "Including font assets", since we are doing that
in "fonts.scss".
 */

//////////////////////////////////////////////////////////////////
// Colour palette schemes - Primary & Secondary are mandatory
// Super-useful tool: http://mcg.mbitson.com/
//////////////////////////////////////////////////////////////////
$primary-scheme: (
  50 : #ffebe0,
  100 : #ffccb3,
  200 : #ffab80,
  300 : #ff894d,
  400 : #ff6f26,
  500 : #ff5600,
  600 : #ff4f00,
  700 : #ff4500,
  800 : #ff3c00,
  900 : #ff2b00,
  // Optional hue accent variants
  A100 : #ffffff,
  A200 : #fff4f2,
  A400 : #ffc7bf,
  A700 : #ffb0a6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    // Optional hue accent variants
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$secondary-scheme: (
  50 : #e2f7fe,
  100 : #b7eafe,
  200 : #87ddfd,
  300 : #57cffc,
  400 : #33c4fb,
  500 : #0fbafa,
  600 : #0db3f9,
  700 : #0babf9,
  800 : #08a3f8,
  900 : #0494f6,
  // Optional hue accent variants
  A100 : #ffffff,
  A200 : #eaf5ff,
  A400 : #b7dfff,
  A700 : #9dd3ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    // Optional hue accent variants
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);
$error-scheme: (
  50 : #ffe0e0,
  100 : #ffb3b3,
  200 : #ff8080,
  300 : #ff4d4d,
  400 : #ff2626,
  500 : #f00,
  600 : #f00,
  700 : #f00,
  800 : #f00,
  900 : #f00,
  A100 : #fff,
  A200 : #fff2f2,
  A400 : #ffbfbf,
  A700 : #ffa6a6,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #fff,
    500 : #fff,
    600 : #fff,
    700 : #fff,
    800 : #fff,
    900 : #fff,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

//////////////////////////////////////////////////////////////////
// Colour palette definitions
//////////////////////////////////////////////////////////////////

// Default hues is 500, which is #ff5600, the original colour of the client
$primary-palette: mat.m2-define-palette($primary-scheme, 500);

// Default hues is 500, which is #0fbafa, the original colour of the client
$secondary-palette: mat.m2-define-palette($secondary-scheme, 500);

// Default hues is 500, which is #ff0000, the original colour of the client
$error-palette: mat.m2-define-palette($error-scheme, 500);


//////////////////////////////////////////////////////////////////
// Typography configuration
// All CSS class mapping: https://material.angular.io/guide/typography#typography-levels
//////////////////////////////////////////////////////////////////

$typography: mat.m2-define-typography-config(
  // Same font as the one defined in fonts.scss
  $font-family: 'Avenir Next',
  $headline-1: mat.m2-define-typography-level(112px, 112px, 300, $letter-spacing: -0.05em),
  $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $letter-spacing: -0.02em),
  $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $letter-spacing: -0.005em),
  $headline-4: mat.m2-define-typography-level(34px, 40px, 400),
  $headline-5: mat.m2-define-typography-level(45px, 48px, 400),
  $headline-6: mat.m2-define-typography-level(32px, 38px, 600),
  $subtitle-1: mat.m2-define-typography-level(24px, 31.2px, 600),
  $subtitle-2: mat.m2-define-typography-level(18px, 21px, 400),
  $body-1: mat.m2-define-typography-level(18px, 24px, 400),
  $body-2: mat.m2-define-typography-level(14px, 20px, 400),
  $caption: mat.m2-define-typography-level(14px, 18px, 400),
  $button: mat.m2-define-typography-level(17px, 23px, 600)
);


//////////////////////////////////////////////////////////////////
// Foreground theming - for texts and other similar items
// All possible configs: https://material.angular.io/guide/theming-your-components#reading-color-values
//////////////////////////////////////////////////////////////////

// No need to define all items, just the ones we need
$light-foreground-theme: (
  base: #263F41,
  secondary-text:#263F41,
  text: #263F41,
);

//////////////////////////////////////////////////////////////////
// Background theming - for backgrounds in button, cards and similar
// All possible configs: https://material.angular.io/guide/theming-your-components#reading-color-values
//////////////////////////////////////////////////////////////////

// No need to define all items, just the ones we need
$light-background-theme: (
  status-bar: #fff,
  app-bar: #fff,
  card: rgb(255 255 255 / 90%),
  background: #fff,
  raised-button: #fff,
  disabled-button: rgb(0 0 0 / 25%),
);

//////////////////////////////////////////////////////////////////
// Theme - Requires colour palettes and typography
//////////////////////////////////////////////////////////////////

$light-theme: mat.m2-define-light-theme((
  color: (
    // Previously defined palettes
    primary: $primary-palette,
    accent: $secondary-palette,
    warn: $error-palette
  ),
  // Previously defined typography
  typography: $typography,
  // Default density. To know more about it, read this:
  // https://material.angular.io/guide/theming#customizing-density
  density: 0
));

// Since mat.define-light-theme does not allow to pass "foreground" or
// "background" in its "color" parameter, we'll need to add the style for
// those later on.
// CSS works like maps, cascades, so we need to get the correct property to
// be modified first by "digging in the map" by adding all levels in map.set().
// Lastly, we set the parameter we want.
// Cascade structure: Theme > Color > Foreground/Background
// _NOTE_: This requires you to import sass:map -> @use "sass:map";
$light-theme: map.set($light-theme, 'color', 'background', $light-background-theme);
$light-theme: map.set($light-theme, 'color', 'foreground', $light-foreground-theme);

//////////////////////////////////////////////////////////////////
// Final implementation - Make the application use the config
//////////////////////////////////////////////////////////////////

@include mat.all-component-themes($light-theme);
@include mat.typography-hierarchy($typography);